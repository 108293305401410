import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import MyNavbar from '../components/navbar/navbar';
import Footer from '../components/footer';
import Helmet from 'react-helmet';

import top_img from '../images/about-top.jpg';
import left_img from '../images/about-left.jpg';
import right_img from '../images/about-right.jpg';
import favicon from '../images/favicon.ico';

function About() {
    return (
        <Layout>
            <Helmet>
                <title>om oss - Danas Plåt &amp; Smide AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="plåtslageri och smidesverkstad som tar uppdrag i Södertälje, Stockholm, Mälarområdet, Södermanland och Uppland." />
                <meta name="keywords" content="plåt, smide, Blästring, Rostskyddsmålning, Termisk Sprutning" />
                <meta property="og:title" content="om oss - Danas Plåt och Smide AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://danasplat.se/om-oss" />
                <link rel="cannonical" href="https://danasplat.se/om-oss" />
            </Helmet>
            
            <MyNavbar active_page="om-oss" />
            <section>
                <div className="container mx-auto">
                    <div className="w-full md:w-2/3 lg:w-1/2 mx-auto px-4 pt-24">
                        <h1 className="text-3xl md:text-4xl font-extrabold text-center text-green-700">Välkommen till oss</h1>
                        <p className="text-gray-600 mt-6">Våra medarbetare är välutbildade och har den kompetens, de licenser, prövningar och certifieringar som krävs för att utföra de avancerade svetsuppdrag vi jobbar med.</p>
                        <p className="text-gray-600 my-6">Svetsare och övrig personal utbildas fortlöpande och uppdateras gällande metoder, regler och yrkesspecifik kunskap. Arbetet och tillverkningen skall ske under säkra former och skall inte påverka medarbetare och arbetsresultat negativt. 
                            <Link to="/kontakt" className="text-red-700 hover:text-red-500 pl-2">Välkommen att kontakta oss!</Link>
                        </p>
                    </div>
                </div>

                {/*****  Contact cards  - START  ******/} 
                <section className="text-gray-600 body-font">
                    <div className="container px-4 md:px-16 py-24 w-full lg:w-2/3 mx-auto">
                        <div className="flex flex-wrap -mr-4 -ml-4">
                            <div className="p-4 w-full md:w-1/2 2xl:w-1/3">
                                <div className="flex flex-col items-center justify-center rounded-lg h-full bg-gray-100 md:bg-gradient-to-b md:from-gray-100 md:via-gray-100 md:to-white p-8 ">      
                                    <div className="w-8 h-8 inline-flex items-center justify-center rounded-full bg-green-700 text-white flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                    </div>
                                    <h2 className="my-4 text-gray-900 text-lg title-font font-medium">Ravin Touma</h2>
                                    <p className="leading-relaxed text-base mb-4">
                                        <span className="bg-white px-2 py-1 rounded-xl shadow">CEO</span>
                                    </p>
                                    <p className="leading-relaxed text-base">076-090 80 88</p>
                                    <p className="leading-relaxed text-base">ravin@danasplat.se</p>
                                </div>
                            </div>

                            <div className="p-4 w-full md:w-1/2 2xl:w-1/3">
                                <div className="flex flex-col items-center justify-center rounded-lg h-full bg-gray-100 md:bg-gradient-to-b md:from-gray-100 md:via-gray-100 md:to-white p-8 ">      
                                    <div className="w-8 h-8 inline-flex items-center justify-center rounded-full bg-green-700 text-white flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                    </div>
                                    <h2 className="my-4 text-gray-900 text-lg title-font font-medium">Carlos Abdul-Jalil</h2>
                                    <p className="leading-relaxed text-base mb-4">
                                        <span className="bg-white px-2 py-1 rounded-xl shadow">Konsult/Projektledare</span>
                                    </p>
                                    <p className="leading-relaxed text-base">076-568 88 10</p>
                                    <p className="leading-relaxed text-base">info@danasplat.se</p>
                                </div>
                            </div>

                            <div className="p-4 w-full md:w-1/2 2xl:w-1/3">
                                <div className="flex flex-col items-center justify-center rounded-lg h-full bg-gray-100 md:bg-gradient-to-b md:from-gray-100 md:via-gray-100 md:to-white p-8 ">      
                                    <div className="w-8 h-8 inline-flex items-center justify-center rounded-full bg-green-700 text-white flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                    </div>
                                    <h2 className="my-4 text-gray-900 text-lg title-font font-medium">Olivia Moranson</h2>
                                    <p className="leading-relaxed text-base mb-4">
                                        <span className="bg-white px-2 py-1 rounded-xl shadow">Projektadministratör</span>
                                    </p>
                                    <p className="leading-relaxed text-base">070-446 22 51</p>
                                    <p className="leading-relaxed text-base">olivia@danasplat.se</p>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </section>
                {/*****  Contact cards  - END  ******/}   



                <div className="container px-4 md:px-16 w-full lg:w-2/3 mx-auto">
                    <div className="w-full flex flex-col">
                        <div className="w-full h-60 lg:h-96 bg-gray-100 mb-4">
                            <img alt="gallery" className="w-full object-cover h-full object-top block" src={top_img} />
                        </div>

                        <div className="w-full h-96 flex flex-row">
                            <div className="w-1/2  h-full mr-2">
                                <img alt="gallery" className="w-full object-cover h-full object-top block" src={left_img} />
                            </div>
                            <div className="w-1/2  h-full ml-2">
                                <img alt="gallery" className="w-full object-cover h-full object-top block" src={right_img} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full h-40 flex justify-center items-center my-20 ">
                    <Link to="/kontakt" className="bg-red-700 text-white text-md md:text-xl p-4 rounded hover:bg-red-800">Kontakta Oss</Link>
                </div>
            </section>
            <Footer />
        </Layout>
    );
}
export default About;